import NhaHang from "../assets/images/industry/nhahang.webp";
import KhachSan from "../assets/images/industry/khachsan.webp";
import CheBienThucPham from "../assets/images/industry/chebienthucpham.webp";
import XayDung from "../assets/images/industry/xaydung.webp";
import DieuDuong from "../assets/images/industry/dieuduong.webp";
import CoKhi from "../assets/images/industry/cokhi.webp";
import DienTu from "../assets/images/industry/diendientu.webp";
import NongNghiep from "../assets/images/industry/nongnghiep.webp";
import ImageDefault from "../assets/images/featured-job/img-default.webp";
import ImageUrgent from "../assets/images/icon-job/urgent.webp";
import ImageHot from "../assets/images/icon-job/hot.webp";
import ImageVip from "../assets/images/icon-job/vip.webp";
import VietNam from "../assets/images/flags/vietnam.jpg";
import Japan from "../assets/images/flags/japan.jpg";
import Korea from "../assets/images/flags/korea.jpg";
import Germany from "../assets/images/flags/germany.jpg";
import Singapore from "../assets/images/flags/singapore.jpg";
import Qatar from "../assets/images/flags/qatar.jpg";
import Cyprus from "../assets/images/flags/cyprus.jpg";
import Taiwan from "../assets/images/flags/taiwan.jpg";
import Moment from 'moment';
let countries = [
    { id: 1, image: VietNam },
    { id: 2, image: Japan },
    { id: 3, image: Korea },
    { id: 4, image: Germany },
    { id: 6, image: Taiwan },
    { id: 8, image: Singapore },
    { id: 9, image: Qatar },
    { id: 65, image: Cyprus }
];

let categories = [
    { id: 1401, image: NhaHang },
    { id: 2607, image: KhachSan },
    { id: 2348, image: CheBienThucPham },
    { id: 2496, image: XayDung },
    { id: 2570, image: DieuDuong },
    { id: 465, image: CoKhi },
    { id: 745, image: DienTu },
    { id: 1898, image: NongNghiep }
];
export const getCityName = (countryData,cityId) => {
    let cityName = '';
    countryData && countryData.map((item, key) => {
      item.cities.map((itemCity, keyCity) => {
        if (itemCity.id == cityId) {
          cityName = itemCity.name;
        }
      })
    })
    return cityName;
  }

export const getIconCountry = (countryData, cityId) => {
    let countryImage = [];
    countryData && countryData.map((item, key) => {
        item.cities.map((itemCity, keyCity) => {
            if (itemCity.id == cityId) {
                countryImage = countries.filter(c => c.id == itemCity.countryID);
            }
        })
    })
    return countryImage.length ? countryImage[0].image : "";
}



export const getJobCategory = (catId) => {
    let industryImage = categories.filter(c => c.id == catId);
    return industryImage.length ? industryImage[0].image : ImageDefault;
}

export const removeBackgroundColor = (jobcontent) => {
    if (!jobcontent) return "";
    let content = jobcontent;
    if (jobcontent.indexOf("background-color: rgb(255,255,255);") > -1) {
      content = content.replaceAll("background-color: rgb(255,255,255);", "");
    }
    if (jobcontent.indexOf("<ul>") > -1) {
      content = content.replaceAll("<ul>", "<ul style='color: black'>");
    }
    if (jobcontent.indexOf("color") <= -1 && jobcontent.indexOf("<p>") > -1) {
      content = content.replaceAll("<p>", "<p style='color: black'>");
    }
    return content;
  }


  export const getJobIcon = (job) => {
      let jobPost = "";
      if (job.dateNeeded) {
          let dateNow = new Date();
          let dateNeeded = new Date(job.dateNeeded);
          let postTime = dateNeeded.getTime() - dateNow.getTime();
          let days = Math.floor(postTime / (1000 * 60 * 60 * 24));
          if (Moment(new Date).format("YYYYMMDD") > Moment(job.dateNeeded).format("YYYYMMDD")) {
              jobPost = ""; // todo: hết hạn
              return "";
          } else {
                if(days <= 7) {
                    return ImageUrgent;
                }
              
          }
         
      }
      if(job.featured == 1) {
            return ImageHot;
      }else if(job.featured == 2) {
        return ImageVip;
      }
        return jobPost;
  }

  export const getJobExpireTime = (date_need) => {
    let jobPostExpire = "";
    if (date_need) {
        let dateNow = new Date();
        let dateNeed = new Date(date_need);
        let postExpireTime = dateNeed.getTime() - dateNow.getTime();
        let days = Math.floor(postExpireTime / (1000 * 60 * 60 * 24));
        let hours = Math.floor((postExpireTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((postExpireTime % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((postExpireTime % (1000 * 60)) / 1000);
        if (Moment(new Date).format("YYYYMMDD") == Moment(date_need).format("YYYYMMDD")) {
            if (Moment(new Date).format("HH") == Moment(date_need).format("HH")) {
                if (Moment(new Date).format("mm") == Moment(date_need).format("mm")) {
                    jobPostExpire = "Còn " + seconds + " giây để ứng tuyển";
                } else {
                    jobPostExpire = "Còn " + minutes + " phút để ứng tuyển";
                }
            } else {
                let hourExpired = 24 - Moment(new Date).format("HH");
                jobPostExpire = "Còn " + hourExpired + " giờ để ứng tuyển";
            }
        } else {
            if (Moment(new Date).format("YYYYMMDD") > Moment(date_need).format("YYYYMMDD")) {
                jobPostExpire = "Hết hạn";
            } else {
                (days == 0 && hours < 24) ? (jobPostExpire = "Còn " + hours + " giờ để ứng tuyển") : (jobPostExpire = "Còn " + days + " ngày để ứng tuyển");
            }
        }
        return jobPostExpire;
    }
    else {
        return "";
    }
}
export const createSlug = (str) =>{

    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/đ/g, 'd').replace(/Đ/g, 'D')
      .toLowerCase() // Chuyển thành chữ thường
      .replace(/[^a-z0-9\s-]/g, '') // Loại bỏ ký tự đặc biệt
      .trim() // Loại bỏ khoảng trắng đầu/cuối
      .replace(/\s+/g, '-') // Thay khoảng trắng bằng dấu gạch ngang
      .replace(/-+/g, '-'); // Loại bỏ dấu gạch ngang liên tiếp
  }
export const getIdFromSlug = (str) => {
    return str.substring(str.lastIndexOf("-") + 1, str.length);
};

export const stripHtml = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };