import { _url, timeout } from "../configs/url";
import { customFetch } from "../configs/refreshToken";

export async function createJob(createjob) {
    let url = _url.server + _url.apiCreateJob;
    let dataResponse = customFetch(url, createjob, 'POST', '');
    return dataResponse;
}

export async function updateJob(updatejob) {
    let url = _url.server + _url.apiUpdateJob;
    let dataResponse = customFetch(url, updatejob, 'POST', '');
    return dataResponse;
}

export async function getListJobs() {
    let url = _url.server + _url.apiGetListJob;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function getListJobAll(page, title, country, salary, industry, city, type, experience) {
    let titleFilter = title ? '&title=' + title : '';
    let countryFilter = (country > 0) ? '&country=' + country : '';
    let salaryFilter = salary > 0 ? '&salary=' + salary : '';
    let industryFilter = (industry > 0) ? '&cat=' + industry : '';
    let cityFilter = (city > 0) ? '&city=' + city : '';
    let typeFilter = (type >= 0) ? '&type=' + type : '';
    let experienceFilter = (experience >= 0) ? '&experience=' + experience : '';

    let url = _url.server + _url.apiGetListJobAll + '?page=' + page + titleFilter + countryFilter + salaryFilter + industryFilter + cityFilter + typeFilter + experienceFilter;

    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function getListJobHome() {
    let url = _url.server + _url.apiGetListJobHome;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function getJobsById(id) {
    let url = _url.server + 'Jobs/' + id;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function deleteJob(data) {
    let url = _url.server + _url.apiDeleteJob + '/' + data.id;
    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function deactiveJob(data) {
    let url = _url.server + _url.apiDeactive + '/' + data.id;
    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function activeJob(data) {
    let url = _url.server + _url.apiActive + '/' + data.id;
    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function applyJob(data) {
    let url = _url.server + _url.apiApplyJob;
    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function getJobsByUser(id) {
    let url = _url.server + _url.apiJobByUser + '/' + id;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function getMyJobs(pagenumber) {
    let url = _url.server + _url.apiGetMyJob + '?page=' + pagenumber;
    let dataResponse = customFetch(url, '', 'GET');
    return dataResponse;
}

export async function getAppliedJob(pagenumber) {
    let url = _url.server + _url.apiAppliedJob + '?page=' + pagenumber;
    let dataResponse = customFetch(url, '', 'GET');
    return dataResponse;
}

export async function getAppliedFile(key,applyId) {
    let url = _url.server + _url.apiJobDownloadFile + '?apply=' + applyId+'&file='+key;
    let dataResponse = customFetch(url, '', 'GET');
    return dataResponse;
}

export async function getJobByCompany(username, pagenumber) {
    let url = _url.server + _url.apiJobByCompany + username + '?page=' + pagenumber;
    let header = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return timeout(fetch(url, {
        method: 'GET',
        headers: header,
    }).then(res => res.json()));
}

export async function getMyApplied() {
    let url = _url.server + _url.apiGetMyApplied;
    let dataResponse = customFetch(url, '', 'GET');
    return dataResponse;
}

export async function getMysuggestion() {
    let url = _url.server + _url.apiGetMysuggestion;
    let dataResponse = customFetch(url, '', 'GET');
    return dataResponse;
}

export async function confirmMysuggestion(data) {
    let url = _url.server + _url.apiGetMysuggestion;
    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}

export async function updateApplyStatus(data) {
    let url = _url.server + _url.apiApplyChange;
    let dataResponse = customFetch(url, data, 'POST', '');
    return dataResponse;
}