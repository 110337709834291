import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {  Modal, ModalBody } from "reactstrap";


const ConfirmModal = ({ isOpen, onClose, onConfirm , modalTitle , modalContent,modalContent2, modalButton}) => {


  return (
    <React.Fragment>
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={isOpen}  centered tabIndex="-1" backdrop="static">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {modalTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={ () => onClose() }
              ></button>
            </div>
            <ModalBody>
              <div>
                <h6 className="text-danger">
                  <i className="uil uil-exclamation-triangle"></i>
                  {modalContent}
                </h6>
                <p className="text-muted">
                  {modalContent2}
                  
                </p>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                onClick={()=>onClose()}
                className="btn btn-primary btn-sm"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={()=>onConfirm()}
              >
                {modalButton}
              </button>
            </div>
          </Modal>
        </div>
    </React.Fragment>
    
       
  );
}

export default ConfirmModal;